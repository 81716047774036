<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="getLocaleText(parameter, 'name')" :isNewButton="checkPermission('parameteritem_store')"
                        @new-button-click="createFormShow" @filter-div-status="datatable.filterStatus = $event"
                        v-if="parameter">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="getLocaleText(parameter, 'name')" :isNewButton="checkPermission('parameteritem_store')"
                              @new-button-click="createFormShow" @filter-div-status="datatable.filterStatus = $event"
                              v-if="parameter">
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6">
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="datatable.queryParams.filter.name">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                       :total="datatable.total" :queryParams="datatable.queryParams" :lineNumbers="false"
                       @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable">
            </datatable>

            <CommonModal ref="showModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()"
                         v-if="checkPermission('parameteritem_show')">
                <template v-slot:CommonModalTitle>
                    {{ $t('show').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <show-form :formId="formId" v-if="formProcess == 'show'" />
                </template>
            </CommonModal>
            <CommonModal ref="createFormModal" size="lg" :onHideOnlyX="true" @bHideModalHeaderClose="formClear"
                         v-if="checkPermission('parameteritem_store')">
                <template v-slot:CommonModalTitle>
                    {{ $t('new').toLocaleUpperCase('tr-TR') }}
                </template>
                <template v-slot:CommonModalContent>
                    <create-form @createFormSuccess="createFormSuccess"
                                 :parameter_id="parameter_id"
                                 :parameter="parameter"
                                 v-if="formProcess == 'create'" />
                </template>
            </CommonModal>
            <CommonModal ref="updateFormModal" size="lg" :onHideOnlyX="true" @bHideModalHeaderClose="formClear"
                         v-if="checkPermission('parameteritem_update')">
                <template v-slot:CommonModalTitle>
                    {{ $t('edit').toLocaleUpperCase('tr-TR') }}
                </template>
                <template v-slot:CommonModalContent>
                    <update-form :formId="formId"
                                 :parameter="parameter"
                                 @updateFormSuccess="updateFormSuccess"
                                 v-if="formProcess == 'update'" />
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
//Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
//Component
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import CommonModal from "@/components/elements/CommonModal";
//Other
import qs from "qs";
//Service
import ParameterService from "@/services/ParameterService";
import ParameterItemService from "@/services/ParameterItemService"
//Page
import CreateForm from "./ParameterItem/CreateForm"
import UpdateForm from "./ParameterItem/UpdateForm"
import ShowForm from "./ParameterItem/ShowForm"


export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        CommonModal,
        CreateForm,
        UpdateForm,
        ShowForm
    },
    metaInfo() {
        return {
            title: this.$t("parameter"),
        };
    },
    data() {
        return {
            parameter: null,
            parameter_id: 0,
            formId: 0,
            form: {},
            formProcess: null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t('show'),
                                class: 'ri-search-eye-line',
                                permission: 'parameteritem_show',
                                callback: (row) => {
                                    this.showForm(row.id);
                                }
                            },
                            {
                                text: this.$t("edit"),
                                class: "ri-edit-box-line",
                                callback: (row) => {
                                    this.formId = row.id;
                                    this.loadData();
                                },
                            },
                            {
                                text: this.$t("delete"),
                                class: "ri-delete-bin-2-line",
                                callback: (row) => {
                                    this.delete(row.id);
                                },
                            },
                        ],
                    },
                    {
                        label: this.$t("id"),
                        field: "id",
                        sortable: true,
                    },
                    {
                        label: this.$t("code"),
                        field: "code",
                        sortable: true,
                    },
                    {
                        label: this.$t("name"),
                        field: "name",
                        sortable: true,
                    },
                    {
                        label: this.$t("status"),
                        field: 'status',
                        sortable: false,
                        formatFn: (row) => {
                            return row == true ? '<span class="badge bg-primary text-light">'+this.$t('active')+'</span>' : '<span class="badge bg-danger text-light">'+this.$t('passive')+'</span>'
                        }
                    },

                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
        };
    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            this.datatable.queryParams.filter.parameter_id = this.parameter_id;
            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false }),
            };

            return ParameterItemService.getAllItems(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        loadData() {
            this.formProcess = "update";
            ParameterItemService.getItem(this.formId)
                .then((response) => {
                    this.form = response.data.data;
                    this.$refs.updateFormModal.$refs.commonModal.show();
                })
                .catch((error) => {
                    if (error.data.message) {
                        this.$toast.error(this.$t("api." + error.data.message));
                    }
                });
        },
        delete(id) {
            this.deleteModal(() => {
                ParameterService.deleteItem(id)
                    .then((response) => {
                        this.filter();
                        this.$toast.success(this.$t("api." + response.data.message));
                    })
                    .catch((error) => {
                        this.$toast.error(this.$t("api." + error.data.message));
                    });
            });
        },
        async createFormSuccess() {
            this.$refs.createFormModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        },
        async updateFormSuccess() {
            this.$refs.updateFormModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        },
        createFormShow() {
            this.formId = null
            this.form = {};
            this.formProcess = 'create'
            this.$refs.createFormModal.$refs.commonModal.show()
        },
        formClear() {
            this.formId = null
            this.formProcess = null
        },
        showForm(id) {

            this.formId = id
            this.formProcess = 'show'
            this.$refs.showModal.$refs.commonModal.show()
        },
    },
    created() {
        this.parameter_id = this.$route.params.id;
        if (this.parameter_id) {
            ParameterService.get(this.parameter_id).then(response => {
                this.parameter = response.data.data;
                this.getRows()
            });
        }
        else {
            this.$router.push('/404');
        }
    },
};
</script>

